export const placeInfo=[
    {
        id:1,
        headerTitle:'Frontend',
        headerSubtitle:'React, Css, SE6',
        description:'Click on below links. Used top new technologies as REact, Angular framework, ECMAScript 6 with webGl and Three library',
        works:'Jobs done',
        example1:'School admin SaaS React, Nodejs',
        example2:'Car renting SaaS webapp React, Nodejs',
        example3:'This portfolio was builded with React ',
        example4:'Angular mechanic admin sebapp, Node js',
        lexample1:'https://clincoln.rosystems.net/',
        lexample2:'https://rentcars.rosystems.net/',
        lexample3:'',
        lexample4:'https://talleradmin.rosystems.net/'
    },
    {
        id:2,
        headerTitle:'Backend',
        headerSubtitle:'SQL, Spring Boot framework, Data migration, Node, Heroku',
        description:'Large systems built using different components with Spring Boot , sql, json, ajax, java, Node, Some of the backend repositories built using Node REST are private for security reasons',
        works:'Projects',
        example1:'https://github.com/nefigarcia',
        lexample1:'https://github.com/nefigarcia'

    },
    {
        id:3,
        headerTitle:'Data',
        headerSubtitle:'HP AML, SQL,Access, Excel, MongoDB',
        description:'Experience with Data migration to Big data using HP ALM tool. Data analyst, macros design visul basic, accees',
        works:'Projects',
        example1:'ask me to show you some examples'
    }
];
export const detailInfo=
{
    id:1,
    headerTitle:'Frontend',
    

};